<template>
	<div class="com-right-content text-white">
		<div >
			<BlockBox title="今日巡逻情况" height="306">
				<template #content>
					<div style="padding:0 15px;box-sizing: border-box">
						<div id="chartCddjtj" style="height:225px;"></div>
					</div>
				</template>
			</BlockBox>
		</div>
		<div class="margin-top-15">
			<BlockBox title="今日线路巡逻完成次数" height="306">
				<template #content>
					<div style="padding:0 15px;box-sizing: border-box">
						<div id="chartWzbjtj" style="height:225px;"></div>
					</div>
				</template>
			</BlockBox>
		</div>

		<div class="margin-top-15">
			<BlockBox title="本周巡逻次数统计" height="306">
				<template #content>
          <div style="padding:0 15px;box-sizing: border-box">
            <div class="flex justify-between align-end">
              <div class="base-font-12 text-white" style="opacity: 0.5;">单位：次数</div>
            </div>
            <div id="chartBzxl" style="height:225px;"></div>
          </div>
				</template>
			</BlockBox>
		</div>
		
	</div>
</template>

<script>
	import echartMixins from "@/utils/resizeMixins";
	import BlockBox from '@/views/components/blockBox'
  import {rightData} from "@/api/api";
	export default {
		name: "cityGreenLand",
		components: {BlockBox},
		mixins: [echartMixins],
		data() {
			return {
        item:{},
				nhLable: ['访问人数', '成交次数'],
        dkList:[{name:'阜阳网点',value:12},{name:'驻马店网点',value:8},{name:'宿州网点',value:7},{name:'东旗网点',value:7},{name:'西乌旗',value:5},{name:'齐齐哈尔网点',value:5},{name:'佳木斯网点',value:4},{name:'石河子',value:2}],
        nhLableCurrent:0,
				colors: ['#f0792a', '#4b3eeb', '#247bf1', "#67e591", '#05d3f8', '#c0f02a', ],
				wzdjPm:[
					{id:1,title:"文章名称文章名称文章名称文章名称文章名称",djl:13234},
					{id:1,title:"文章名称文章名称文章名称文章名称文章名称",djl:13234},
					{id:1,title:"文章名称文章名称文章名称文章名称文章名称",djl:13234},
					{id:1,title:"文章名称文章名称文章名称文章名称文章名称",djl:13234},
					{id:1,title:"文章名称文章名称文章名称文章名称文章名称",djl:13234},
					{id:1,title:"文章名称文章名称文章名称文章名称文章名称",djl:13234},
					{id:1,title:"文章名称文章名称文章名称文章名称文章名称",djl:13234},

				]
				// mrhnDay: ['12/05', '12/06', '12/07', '12/08', '12/09'],
			}
		},
		mounted() {
			this.changeData()
		},
		methods: {
      changeData(){
        let _self=this
        rightData().then(res => {
          _self.item=res.data;
          _self.$nextTick(function() {
            _self.drawCddjtj();
            _self.drawWzbjtj();
            _self.drawBzxl();
          });
        });
      },
      drawBzxl() {
        this.chart = this.$echarts.init(document.getElementById("chartBzxl"));
        //let data=[{name: "7月", value: 0},{name: "8月", value: 0},{name: "9月", value: 203},{name: "10月", value: 502},{name: "11月", value: 865},{name: "12月", value: 1306}]

        let dateList=[];
        let dateValue=[];
        this.item.weekList.forEach(obj=>{
          dateList.push(obj.name);
          dateValue.push(obj.value);
        })
        let option = {
          grid: {
            left: '3%',
            right: '3.5%',
            bottom: '3%',
            top: 30,
            containLabel: true
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            data: dateList,
            axisLabel: {
              textStyle: {
                color: 'rgba(255,255,255,0.7)', //更改坐标轴文字颜色
                fontSize: 10 //更改坐标轴文字大小
              }
            },
            splitLine: { //网格线
              lineStyle: {
                type: 'dotted', //设置网格线类型 dotted：虚线   solid:实线
                color: ['rgba(45,163,255,0.1)']
              },
              show: true //隐藏或显示
            },
            axisLine: {
              show: false, //隐藏或显示
              lineStyle: {
                color: '#2da3ff'
              }
            }
          },
          yAxis: {
            type: 'value',
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: 'rgba(255,255,255,0.7)', //更改坐标轴文字颜色
                fontSize: 10 //更改坐标轴文字大小
              }
            },
            splitLine: { //网格线
              lineStyle: {
                type: 'dotted', //设置网格线类型 dotted：虚线   solid:实线
                color: ['rgba(45,163,255,0.1)']
              },
              show: true //隐藏或显示
            },
            axisLine: {
              show: false, //隐藏或显示
              lineStyle: {
                color: '#ffffff',
                fontSize: 10
              }
            }
          },
          series: [{
            name: '巡逻次数',
            type: 'line',
            smooth: true,
            data: dateValue,
            showSymbol: false,
            symbol: 'emptyCircle', // 几何圆
            symbolSize: 5,
            itemStyle: {
              color: '#03DBF4'
            },
            areaStyle: { // 区域填充样式
              color: { // 填充的颜色 // 线性渐变，前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: '#03DBF4', // 0% 处的颜色
                },
                  {
                    offset: 1,
                    color: 'rgba(37,234,255,0)' // 100% 处的颜色
                  }
                ],
                global: false, // 缺省为 false
              }
            },
          }, ]
        };
        // echart图表自适应
        this.chart.setOption(option)
        window.addEventListener("resize", () => {
          this.chart.resize();
        });
      },
			drawWzbjtj() {
				this.chart = this.$echarts.init(document.getElementById("chartWzbjtj"));
        let dateList=[];
        let dateValue=[];
        //let list=[{name:'阜阳网点',value:12},{name:'驻马店网点',value:8},{name:'宿州网点',value:7},{name:'东旗网点',value:7},{name:'齐齐哈尔网点',value:5},{name:'佳木斯网点',value:4},{name:'西乌旗',value:5},{name:'石河子',value:2}];
        this.item.lineList.forEach(obj=>{
          dateList.push(obj.name);
          dateValue.push(obj.value);
        })
				let option = {
					grid: {
						left: '3%',
						right: '3.5%',
						bottom: '3%',
						top:20,
						containLabel: true
					},
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						type: 'category',
						
						axisTick: {
							show: false,
						},
						data:dateList,
						axisLabel: {
							textStyle: {
								color: 'rgba(255,255,255,0.7)', //更改坐标轴文字颜色
								fontSize: 10 //更改坐标轴文字大小
							}
						},
						splitLine: { //网格线
							lineStyle: {
								type: 'dotted', //设置网格线类型 dotted：虚线   solid:实线
								color: ['rgba(45,163,255,0.1)']
							},
							show: true //隐藏或显示
						},
						axisLine: {
							show: false, //隐藏或显示
							lineStyle: {
								color: '#2da3ff'
							}
						}
					},
					yAxis: {
						type: 'value',
						axisTick: {
							show: false,
						},
						axisLabel: {
							textStyle: {
								color: 'rgba(255,255,255,0.7)', //更改坐标轴文字颜色
								fontSize: 10 //更改坐标轴文字大小
							}
						},
						splitLine: { //网格线
							lineStyle: {
								type: 'dotted', //设置网格线类型 dotted：虚线   solid:实线
								color: ['rgba(45,163,255,0.1)']
							},
							show: true //隐藏或显示
						},
						axisLine: {
							show: false, //隐藏或显示
							lineStyle: {
								color: '#ffffff',
								fontSize: 10
							}
						}
					},
					series: [
						{
						name: '数量',
						type: 'bar',
						barWidth:9,
						data: dateValue,
						itemStyle: {
								normal: {
									label: {
										show: true, //开启显示
										position: 'top', //在上方显示
										textStyle: {
											//数值样式
											color: 'white',
											fontSize: 12,
										},
									},
								color: { // 填充的颜色 // 线性渐变，前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [{
											offset: 0,
											color: 'rgba(71, 71, 255, 1)', // 0% 处的颜色
										},
										{
											offset: 1,
											color: 'rgba(51, 30, 255, 1)' // 100% 处的颜色
										}
									],
									global: false, // 缺省为 false
								}
						      }
							  },
			
				
						}
					]
				};
				// echart图表自适应
				this.chart.setOption(option)
				window.addEventListener("resize", () => {
					this.chart.resize();
				});
			},
					
			drawCddjtj() {
				this.chart = this.$echarts.init(document.getElementById("chartCddjtj"));
        //let data=[{name: "待巡逻", value: 22},{name: "进行中", value: 5},{name: "已完成", value: 8}]
        let dateList=[];
        let dateValue=[];
        this.item.list.forEach(obj=>{
          dateList.push(obj.name);
          dateValue.push(obj.value);
        })
				let option = {
					grid: {
						left: '3%',
						right: '3.5%',
						bottom: '3%',
						top:20,
						containLabel: true
					},
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						type: 'category',
						
						axisTick: {
							show: false,
						},
						data:dateList,
						axisLabel: {
							textStyle: {
								color: 'rgba(255,255,255,0.7)', //更改坐标轴文字颜色
								fontSize: 10 //更改坐标轴文字大小
							}
						},
						splitLine: { //网格线
							lineStyle: {
								type: 'dotted', //设置网格线类型 dotted：虚线   solid:实线
								color: ['rgba(45,163,255,0.1)']
							},
							show: true //隐藏或显示
						},
						axisLine: {
							show: false, //隐藏或显示
							lineStyle: {
								color: '#2da3ff'
							}
						}
					},
					yAxis: {
						type: 'value',
						axisTick: {
							show: false,
						},
						axisLabel: {
							textStyle: {
								color: 'rgba(255,255,255,0.7)', //更改坐标轴文字颜色
								fontSize: 10 //更改坐标轴文字大小
							}
						},
						splitLine: { //网格线
							lineStyle: {
								type: 'dotted', //设置网格线类型 dotted：虚线   solid:实线
								color: ['rgba(45,163,255,0.1)']
							},
							show: true //隐藏或显示
						},
						axisLine: {
							show: false, //隐藏或显示
							lineStyle: {
								color: '#ffffff',
								fontSize: 10
							}
						}
					},
					series: [
						{
						name: '次数',
						type: 'bar',
						barWidth:9,
						data: dateValue,
						itemStyle: {
								normal: {
									label: {
										show: true, //开启显示
										position: 'top', //在上方显示
										textStyle: {
											//数值样式
											color: 'white',
											fontSize: 12,
										},
									},
								color: { // 填充的颜色 // 线性渐变，前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [{
											offset: 0,
											color: 'rgba(1, 108, 190, 1)', // 0% 处的颜色
										},
										{
											offset: 1,
											color: 'rgba(1, 38, 77, 1)' // 100% 处的颜色
										}
									],
									global: false, // 缺省为 false
								}
						      }
						},
	
					}, 
					]
				};
				// echart图表自适应
				this.chart.setOption(option)
				window.addEventListener("resize", () => {
					this.chart.resize();
				});
			},
		
			
		}
	}
</script>

<style lang="scss">
	.com-right-content {
		min-height: 500px;
		padding: 14px 15px 15px 15px;
		box-sizing: border-box;
		.wzdjItem{width:100%;height:35px;line-height:21px;padding:11px 0;cursor: pointer;}
		.wzdjItem:hover{color:#006AF6;}
		.wzdjItem .leftNum{width:21px;height:21px;border-radius:2px;background:#006AF6;line-height:21px;text-align: center;}
		.wzdjItem .leftNum.one{
			background: -webkit-linear-gradient(top, rgba(255, 72, 0, 1), rgba(178, 0, 0, 1));
			/* Safari 5.1 - 6.0 */
			background: -o-linear-gradient(bottom, rgba(255, 72, 0, 1), rgba(178, 0, 0, 1));
			/* Opera 11.1 - 12.0 */
			background: -moz-linear-gradient(bottom,rgba(255, 72, 0, 1), rgba(178, 0, 0, 1));
			/* Firefox 3.6 - 15 */
			background: linear-gradient(to bottom,rgba(255, 72, 0, 1), rgba(178, 0, 0, 1));
		}
		.wzdjItem .leftNum.two{
			background: -webkit-linear-gradient(top, rgba(255, 174, 0, 1), rgba(167, 98, 0, 1));
			/* Safari 5.1 - 6.0 */
			background: -o-linear-gradient(bottom,  rgba(255, 174, 0, 1), rgba(167, 98, 0, 1));
			/* Opera 11.1 - 12.0 */
			background: -moz-linear-gradient(bottom, rgba(255, 174, 0, 1), rgba(167, 98, 0, 1));
			/* Firefox 3.6 - 15 */
			background: linear-gradient(to bottom, rgba(255, 174, 0, 1), rgba(167, 98, 0, 1));
		}
		.shtjBox{
			height:250px;
			 & .swiper{height:250px;}
		}
	}
	

	.hnzbLable {
		margin-top: 10px;
	}

	.hnzbLable .itemLable {
		line-height: 1;
		border: 1px solid;
		color: #2196f3;
		font-size: 12px;
		padding: 5px 7px;
		border-radius:1px;
	}

	.hnzbLable .itemLable+.itemLable {
		margin-left: 7px;
	}

	.hnzbLable .itemLable.checked {
		background: #006AF6;
		color: #FFFFFF;
		border-color: #006AF6;
	}

</style>
